<template>
  <div class="AvatarUploader">
    <div
      class="AvatarUploader__contentWrapper"
      :class="{ 'AvatarUploader__contentWrapper--square': square }"
    >
      <input
        :id="fileInputId"
        @change="onChange"
        type="file"
        accept=".jpg, .jpeg, .png"
        ref="fileInput"
        class="AvatarUploader__uploadFileInput"
      />
      <label v-if="!disabled" :for="fileInputId" class="AvatarUploader__uploadButton" />
      <transition name="fade">
        <div v-if="fileBase64 || filePlaceholder || item" class="AvatarUploader__preview">
          <img
            :src="fileBase64 ? fileBase64 : item ? formatImage(item.path) : filePlaceholder"
            alt="File Preview"
            class="AvatarUploader__previewImage"
          />
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="errorMessage && !fileBase64"
          class="AvatarUploader__errorMessage AvatarUploader__errorMessage--row"
        >
          {{ errorMessage }}
        </div>
      </transition>
      <transition name="fade">
        <button
          v-if="fileBase64 && !disabled"
          @click.prevent="removeFile"
          class="AvatarUploader__removeButton"
        ></button>
      </transition>
    </div>
  </div>
</template>

<script>
import { getRemoteFilePath } from '@/utils/helpers/string';

const NAME = 'AvatarUploader';
const RESET_MESSAGE_TIME = 3500;

export default {
  name: NAME,
  props: ['item', 'disabled', 'square'],
  data() {
    return {
      file: null,
      fileBase64: null,
      errorMessage: '',
      filePlaceholder: !this.fileBase64 ? '/img/user-placeholder.svg' : null,
    };
  },
  computed: {
    fileInputId() {
      return `image_uploader_${Date.now()}`;
    },
  },
  methods: {
    formatImage(payload) {
      return getRemoteFilePath(payload);
    },
    onChange(event) {
      const fileType = new RegExp('^image/(png|jpeg|jpg)$');
      const maxSize = 20 * 1024 * 1024;
      if (event.target.files.length) {
        const file = event.target.files[0];
        if (fileType.test(file.type) && file.size <= maxSize) {
          this.errorMessage = '';
          this.$emit('upload', file);
          this.fileToBase64(file)
            .then(result => {
              this.fileBase64 = result;
            })
            .catch(error => {
              throw error;
            });
        } else {
          this.fileBase64 = null;
          this.setErrorMessage('Error uploading your image');
          setTimeout(this.resetErrorMessage, RESET_MESSAGE_TIME);
        }
      }
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => {
          reject(error);
        };
      });
    },
    removeFile() {
      this.fileBase64 = null;
      this.$refs.fileInput.value = null;
      this.$emit('remove');
    },
    setErrorMessage(msg) {
      this.errorMessage = msg;
    },
    resetErrorMessage() {
      this.errorMessage = '';
    },
  },
};
</script>

<style lang="scss" scoped>
$avatar-uploader-side-size: 88px;
$avatar-uploader-button-side-size: 38px;

.AvatarUploader {
  max-width: $avatar-uploader-side-size;
  padding-right: calc(#{$avatar-uploader-button-side-size} / 2);
  box-sizing: content-box;
}
.AvatarUploader__contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  &--square {
    border-radius: 10%;
  }
}
.AvatarUploader__uploadButton {
  position: absolute;
  right: -19px;
  bottom: 0;
  width: $avatar-uploader-button-side-size;
  height: $avatar-uploader-button-side-size;
  border: none;
  border-radius: 50%;
  background: linear-gradient(315deg, #14d2b1 1.05%, #54e839 100%);
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:before {
    content: '+';
    display: block;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    font-size: 20px;
    color: white;
    font-weight: bold;
  }
  &:after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(calc(100% + 10px), -50%);
    content: attr(data-text);
    white-space: nowrap;
    font-size: 14px;
    color: #7d7d80;
    display: block;
  }
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in;
  }
}
.AvatarUploader__uploadFileInput {
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
}
.AvatarUploader__uploadButtonIcon {
  max-height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.AvatarUploader__preview {
  width: $avatar-uploader-side-size;
  height: $avatar-uploader-side-size;
  border-radius: 50%;
  overflow: hidden;
}
.AvatarUploader__previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.AvatarUploader__removeButton {
  width: 13px;
  height: 13px;
  border: none;
  background: url('/icons/cross.svg') 0% 0% / cover no-repeat;
  position: absolute;
  top: 0;
  right: -20px;
}
.AvatarUploader__errorMessage {
  // color: $red;
  font-size: 12px;
  line-height: 1.5;
  vertical-align: middle;
  &--row {
    margin-left: 20px;
  }
}
</style>
